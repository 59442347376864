import React from "react";
import Dashboard from "../assets/png/dashboard.png";
import Vec from "../assets/svg/vec.svg";


export default function Conclusion() {
  return (
    <>
      <div className="bg-[#00347a]">
        <div className="relative grid lg:grid-cols-2 2xl:w-[50%] mx-auto py-20">
          <div>
            <img src={Dashboard} alt="" />
          </div>

          <div className="w-[90%] xl:w-[80%] mx-auto self-center mt-10 lg:mt-0 ">
            <p className="text-[#008dd2] font-[700] text-[45px]">
              Conclusion
            </p>

            <p className="mt-5 font-[500] text-[20px]  text-white ">
              SigPros est la solution technologique idéale pour moderniser et
              optimiser la gestion de la protection sociale. Ses
              caractéristiques personnalisables, ses fonctionnalités avancées et
              ses avantages pour l'État et les organismes en font la référence
              du secteur.
            </p>

            <p className="font-[500] text-[20px]  text-white mt-5">
              Faisons évoluer ensemble notre système de protection sociale avec
              SigPros.
            </p>
          </div>

        <img src={Vec} alt="" className="absolute top-8 left-2 h-[80px] w-[80px]" />

        </div>
      </div>
    </>
  );
}
