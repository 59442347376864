import React from "react";
import Dot from "../assets/svg/image 53.svg";
import Advant from "../assets/png/image 33.png";

export default function Advantage() {
  return (
    <>
      <div className="grid lg:grid-cols-2 2xl:w-[50%] mx-auto py-10 md:py-20 ">
        <div className="w-[90%] lg:w-[80%] mx-auto ">
          <p className=" font-[700] text-[#008dd2] text-[30px] md:text-[50px] ">
            Avantages <span className="text-black"> de SigPros </span>
          </p>
          <div className="flex gap-2 mt-10">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px] text-black">
              Optimisation des opérations administratives pour une meilleure
              efficacité
            </p>
          </div>
          <div className="flex gap-2 mt-3">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px] text-black">
              Réduction des coûts grâce à l'automatisation des tâches
              répétitives
            </p>
          </div>
          <div className="flex gap-2 mt-3">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px] text-black">
              Amélioration de la qualité des services offerts aux bénéficiaires
            </p>
          </div>
          <div className="flex gap-2 mt-3">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px] text-black">
              Prise de décision éclairée grâce à des rapports et analyses précis
            </p>
          </div>
        </div>
        <div className="self-center xl:self-start mt-10 lg:mt-0 ">
          <img src={Advant} alt="" />
        </div>
      </div>
    </>
  );
}


