import React from "react";
import Func from "../assets/png/Group 3.png";
import Icon from "../assets/svg/image 11.svg"
import Icon2 from "../assets/svg/image 12.svg"
import Icon3 from "../assets/svg/image 10.svg"
import Icon4 from "../assets/svg/image 13.svg"
import Icon5 from "../assets/svg/image 14.svg"
import Vector from "../assets/svg/image 23.svg"

export default function Function() {
  return (
    <>
      <div className="bg-[#d4f1ff] py-16 md:py-20 relative">
        <div className="w-[90%] 2xl:w-[50%] mx-auto">
          <p className="font-[700] text-[#008dd2] text-[30px] md:text-[50px] ">
            Fonctionnalités <span className="text-black"> de SigPros </span>
          </p>

          <div className="mt-10 hidden lg:block">
            <img src={Func} alt="" />
            <div className="grid grid-cols-5 gap-5 text-center">
              <div className="-ml-10">
                <p className="font-[700] text-[15px] xl:text-[18px] text-black ">
                  Gestion des dossiers des bénéficiaires de manière centralisée{" "}
                </p>
              </div>
              <div className="-ml-5">
                <p className="font-[700] text-[15px] xl:text-[18px] text-black ">
                  Suivi des demandes d'aides sociales et des prestations{" "}
                </p>
              </div>
              <div className="ml-2 xl:ml-5">
                <p className="font-[700] text-[15px] xl:text-[18px] text-black ">
                  {" "}
                  Gestion des contributions, cotisations et recouvrements{" "}
                </p>
              </div>
              <div className="ml-8 xl:ml-10">
                <p className="font-[700] text-[15px] xl:text-[18px] text-black ">
                  Automatisation des processus administratifs{" "}
                </p>
              </div>
              <div className="ml-12 xl:ml-16">
                <p className="font-[700] text-[15px] xl:text-[18px] text-black ">
                  Génération de rapports statistiques et analytiques{" "}
                </p>
              </div>
            </div>
          </div>
 
          <div className="grid grid-cols-2 mt-10 lg:hidden gap-10">
           <div>
            <img src={Icon} alt="" className="mx-auto" />
            <p className="text-center mt-5 font-[700] text-[15px] xl:text-[18px] text-black ">
                  Gestion des dossiers des bénéficiaires de manière centralisée{" "}
                </p>
           </div>
    
           <div>
            <img src={Icon2} alt="" className="mx-auto" />
            <p className="text-center mt-5 font-[700] text-[15px] xl:text-[18px] text-black ">
            Suivi des demandes d'aides sociales et des prestations 
                </p>
           </div>
   
           <div>
            <img src={Icon3} alt="" className="mx-auto" />
            <p className="text-center mt-5 font-[700] text-[15px] xl:text-[18px] text-black ">
            Gestion des contributions, cotisations et recouvrements 
                </p>
           </div>
 
           <div>
            <img src={Icon4} alt="" className="mx-auto" />
            <p className="text-center mt-5 font-[700] text-[15px] xl:text-[18px] text-black ">
            Automatisation des processus administratifs 
                </p>
           </div>
      
           <div>
            <img src={Icon5} alt="" className="mx-auto" />
            <p className="text-center mt-5 font-[700] text-[15px] xl:text-[18px] text-black ">
            Génération de rapports statistiques et analytiques
                </p>
           </div>
          </div> 
 
        </div>
        <img src={Vector} alt="" className="absolute bottom-2 left-0" />
      </div>
    </>
  );
}
