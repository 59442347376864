import { Typography } from "@material-tailwind/react";

export function FooterWithLogo() {
  return (
    <footer className="w-full bg-[#00347a] p-8 ">
      <div className="2xl:w-[50%] mx-auto">
        <div className="md:flex md:justify-between">
          <p className=" font-inter font-[700] text-[25px] xl:text-[35px] text-white">
            SigProS
          </p>

          <div className="mt-5 md:mt-0  grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-5 self-center">
            <div>
              <a
                href="https://dashboard.sigpros.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className=" font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer ">
                  Bénéficiaire{" "}
                </p>
              </a>
            </div>
            <div>
              <a
                href="https://management.sigpros.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="mt-3 md:mt-0 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                  Gestionnaire{" "}
                </p>
              </a>
            </div>
            <div>
              <a
                href="https://admin.sigpros.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="mt-3 md:mt-0 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                  Administrateur{" "}
                </p>
              </a>
            </div>
            <div>
              <a
                href="https://empreintekapital.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="mt-3 md:mt-0 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                  Site Web d'entreprise
                </p>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-8 border-blue-gray-50" />
        <Typography className="text-center text-white font-inter font-[700]">
          &copy; Copyright 2023 SigPros
        </Typography>
      </div>
    </footer>
  );
}
