import React from "react";
import Lap from "../assets/png/Group 7.png";
import Function from "../component/function";
import Personal from "../component/personal";
import Advantage from "../component/advantage";
import Transformation from "../component/trans";
import Recou from "../component/recou";
import Conclusion from "../component/conclusion";
import Navbar from "../component/navbar";
import { FooterWithLogo } from "../component/footer";

export default function Landing() {
  return (
    <>
    <Navbar/>
      <div className="bg-[#00347a]">
        <div className="grid lg:grid-cols-2 2xl:w-[50%] mx-auto">
          <div className="-mt-28 -ml-16">
            <img src={Lap} alt="" />
          </div>
          <div className="w-[90%] xl:w-[80%] mx-auto self-center">
            <p className="font-[500] text-[20px] md:text-[30px] xl:text-[35px] text-white my-10 lg:mb-0 lg:mt-28 xl:mt-36">
              <span className="text-[#008dd2] font-[700]"> SigPros </span> est
              une application avancée conçue pour répondre aux besoins complexes
              des ministères et des organismes de gestion de la protection
              sociale.
            </p>
          </div>
        </div>
      </div>
      <Function/>
      <Personal/>
      <Advantage/>
      <Transformation/>
      <Recou/>
      <Conclusion/>
      <FooterWithLogo/>
    </>
  );
}
