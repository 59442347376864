import React from "react";
import Trans from "../assets/jpg/image 54.jpg";
import Snack from "../assets/png/Group 77.png";

export default function Transformation() {
  return (
    <>
      <div className="grid lg:grid-cols-2 2xl:w-[50%] mx-auto">
        <div>
          <img src={Trans} alt="" className="" />
        </div>

        <div className="lg:border-b-[2px] border-[#00347a]">
          <div className="bg-[#00347a]">
            <p className="w-[90%] md:w-full mx-auto py-10 px-0  md:py-10 md:px-10 font-[700] text-[40px] lg:text-[25px] xl:text-[40px] text-white">
              Transformation <br /> du secteur <br /> informel
            </p>
          </div>

          <div className="flex gap-10 w-[90%] mx-auto">
            <img src={Snack} alt="" className="w-[15%] md:w-[10%] " />
            <div className="py-10 self-center lg:self-start">
              <p className="font-[500] text-[15px] md:text-[25px] lg:text-[15px] xl:text-[25px] text-black xl:w-[80%]">
                SigPros facilite le recensement et l'intégration des
                travailleurs informels dans le système de protection sociale
              </p>
              <p className="mt-10 font-[500] text-[15px] md:text-[25px] lg:text-[15px] xl:text-[25px] text-black xl:w-[80%]">
                Augmentation de la base de cotisants pour un financement plus
                équilibré
              </p>
              <p className="mt-10 font-[500] text-[15px] md:text-[25px] lg:text-[15px] xl:text-[25px] text-black xl:w-[80%]">
                Amélioration de la couverture sociale pour tous les travailleurs
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
