import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Fragment } from "react";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="bg-[#00347a] relative z-20 border-b-[2px] border-[#d4f1ff]">
        <div className="grid grid-cols-2 w-[90%] 2xl:w-[50%] mx-auto py-5">
          <div className="">
            <p className=" font-inter font-[700] text-[25px] xl:text-[35px] text-white">
              SigProS
            </p>
            <div className="hidden lg:block relative"></div>
          </div>

          <div className="hidden lg:flex justify-between self-center">
            <a
              href="https://dashboard.sigpros.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="hidden lg:block font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer ">
                Bénéficiaire{" "}
              </p>
            </a>
            <a
              href="https://management.sigpros.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="hidden lg:block font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                Gestionnaire{" "}
              </p>
            </a>
            <a
              href="https://admin.sigpros.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="hidden lg:block font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                Administrateur{" "}
              </p>
            </a>
            <a
              href="https://empreintekapital.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="hidden lg:block font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                Site Web d'entreprise
              </p>
            </a>
          </div>

          <div className="block  lg:hidden self-center ml-auto">
            <svg
              onClick={() => setOpen(true)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 text-white "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-xs pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-[#00347a] py-3 shadow-xl">
                      <div class="container grid grid-cols-1 pl-5">
                        <p className="font-inter font-[700] text-[25px] text-white text-start ">
                          SigProS
                        </p>

                        <a
                          href="https://dashboard.sigpros.org"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="mt-5 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer ">
                            Bénéficiaire{" "}
                          </p>
                        </a>
                        <a
                          href="https://management.sigpros.org"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="mt-5  font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                            Gestionnaire{" "}
                          </p>
                        </a>
                        <a
                          href="https://admin.sigpros.org"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="mt-5 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                            Administrateur{" "}
                          </p>
                        </a>
                        <a
                          href="https://empreintekapital.fr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="mt-5 font-inter font-[700] text-[16px] text-white hover:text-[#d4f1ff] cursor-pointer">
                            Site Web d'entreprise
                          </p>
                        </a>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
