import React from "react";
import Icon from "../assets/svg/image 27.svg";
import Icon2 from "../assets/svg/image 29.svg";
import Icon3 from "../assets/svg/image 32.svg";
import Vector from "../assets/svg/image 23.svg"
import Banner from "../assets/png/image 52.png";

export default function Personal() {
  return (
    <>
      <>
        <div className="relative">
          <div className=" pt-10 w-[90%] md:w-[80%] 2xl:w-[50%] mx-auto relative z-10">
            <p className=" font-[700] text-[#008dd2] text-[30px] md:text-[50px] ">
            Personnalisation <span className="text-black"> de SigPros </span>
            </p>
           
            <div className="mt-10 bg-white shadow-lg rounded-full p-2 flex gap-5">
              <div className="bg-[#008dd2] p-3 rounded-full">
                <img src={Icon} alt="" />
              </div>
              <p className="self-center     font-[600] text-[10px] md:text-[15px] lg:text-[20px] text-black">
                Adaptation aux spécificités de chaque ministère et organisme
              </p>
            </div>

            <div className="mt-5 bg-white shadow-lg rounded-full p-2 flex gap-5">
              <div className="bg-[#008dd2] p-3 rounded-full">
                <img src={Icon2} alt="" />
              </div>
              <p className="self-center     font-[600] text-[10px] md:text-[15px] lg:text-[20px] text-black">
                Configuration des règles de calcul des prestations
              </p>
            </div>

            <div className="mt-5 bg-white shadow-lg rounded-full p-2 flex gap-5">
              <div className="bg-[#008dd2] p-3 rounded-full">
                <img src={Icon3} alt="" />
              </div>
              <p className="self-center     font-[600] text-[10px] md:text-[15px] lg:text-[20px] text-black">
                Intégration avec les systèmes existants (ERP) pour une
                interopérabilité 
              </p>
            </div>

         
          </div>
          <img src={Banner} alt="" className="-mt-20 md:-mt-72 2xl:w-[50%] mx-auto" />
          <img src={Vector} alt="" className="absolute top-10 right-0" />

        </div>
      </>
    </>
  );
}
