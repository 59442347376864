import React from "react";
import Dot from "../assets/svg/image 53.svg";
import Rec from "../assets/png/image 44.png";
import Vec from "../assets/svg/vec.svg";

export default function Recou() {
  return (
    <>
      <div className="relative grid lg:grid-cols-2 2xl:w-[50%] mx-auto pb-10 md:pb-10 lg:pb-0">
        <div className="block lg:hidden self-center xl:self-start mt-0 lg:mt-0 ">
          <img src={Rec} alt="" />
        </div>
        <div className="w-[90%] lg:w-[80%] mx-auto mt-20">
          <p className=" font-[700] text-[#008dd2] text-[30px] md:text-[50px] ">
            Avantages <span className="text-black"> de SigPros </span>
          </p>
          <div className="flex gap-2 mt-10">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px]
            text-black">
              SigPros offre une gestion efficace des contributions et des
              recouvrements
            </p>
          </div>
          <div className="flex gap-2 mt-3">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px]
            text-black">
              Réduction de la fraude grâce à des outils de contrôle avancés
            </p>
          </div>
          <div className="flex gap-2 mt-3">
            <img src={Dot} alt="" className="w-[40px] h-[40px]" />
            <p className="self-center font-[500] text-[15px] md:text-[20px] lg:text-[16px] xl:text-[20px]
            text-black">
              Amélioration du recouvrement des taxes pour renforcer les
              ressources de l'État
            </p>
          </div>
        </div>
        <div className="hidden lg:block self-center xl:self-start mt-10 lg:mt-0 ">
          <img src={Rec} alt="" />
        </div>

        <img src={Vec} alt="" className="absolute top-10 right-5 h-[80px] w-[80px]" />
      </div>
    </>
  );
}
